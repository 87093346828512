import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { SetupService } from '../services/setup.service';

export function EnabledAgreementsGuard(): boolean {

  const router = inject(Router);
  const setupService = inject(SetupService);

  if (!setupService.areAgreementsEnabled) {
    router.navigate(['/dashboard']);
    return false;
  }

  return true;


}
