import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';

export function EnabledAgreementFormsGuard(): boolean {
  const router = inject(Router);
  const userService = inject(UserService);

  if (userService.user.allowedAgreementForms) {
    return true;
  }

  router.navigate(['dashboard'], { queryParamsHandling: 'merge' });
  return false;
}
