import { Injectable, Signal, signal } from '@angular/core';
import { ModalService } from './modal.service';
import { CheckDeviceService } from './check-device.service';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  private _sidebarStatus = signal(false);

  constructor(
    private modalService: ModalService,
    private checkDevice: CheckDeviceService
  ) { }

  open(): void {
    this._sidebarStatus.set(true);

    if (this.checkDevice.isMobile()) {
      setTimeout(() => this.modalService.hideScroll(), 300);
    }
  }

  close(): void {
    this._sidebarStatus.set(false);

    if (this.checkDevice.isMobile()) {
      this.modalService.showScroll();
    }

  }

  get sidebarStatus(): Signal<boolean> {
    return this._sidebarStatus;
  }
}
