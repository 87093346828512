import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { AbstractModal } from '../abstract-modal';
import { ModalService } from 'src/app/services/modal.service';
import { Modal } from 'src/app/classes/modal.class';

@Component({
  selector: 'app-dynamic-modal',
  templateUrl: './dynamic-modal.component.html',
  styleUrls: ['./dynamic-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicModalComponent implements OnInit {

  @Input() public modal: Modal = null;

  @ViewChild('dynamicModalContainer', {read: ViewContainerRef, static: true}) dynamicModalContainer: ViewContainerRef;

  constructor(
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    const componentRef = this.dynamicModalContainer.createComponent<AbstractModal>(this.modal.type);
    componentRef.instance.modal = this.modal;
  }

  closeModal(): void {
    if (this.modal.data?.close) {
      this.modal.data.close();
    }

    this.modalService.removeModal(this.modal);
  }
}
